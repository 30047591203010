import 'nprogress/nprogress.css'
import './main.scss'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createAuth0 } from '@auth0/auth0-vue'
import { MotionPlugin } from '@vueuse/motion'
import * as Sentry from '@sentry/vue'

import App from './App.vue'
import router from './router'
import Toast, { TYPE } from 'vue-toastification'
import 'vue-toastification/dist/index.css'

import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import posthog from 'posthog-js'

if (!window.location.host.includes('127.0.0.1') && !window.location.host.includes('localhost')) {
  posthog.init('phc_twtEMMYL3UZAdZCop4aNase1sMZn3kOMxJXfOdLaGLc', {
    api_host: 'https://us.i.posthog.com',
    person_profiles: 'identified_only'
  })
}

const app = createApp(App)

// Sentry.init({
//   app,
//   dsn: 'https://afbd019cfaeb871eba0c6e602493d523@o4507769293176832.ingest.de.sentry.io/4507769435979856',
//   integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: [/^https:\/\/test\.onplad\.com\//],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// })

app.use(createPinia())
app.use(router)
app.use(MotionPlugin)
app.use(
  createAuth0({
    domain: 'dev-gbp1ny1a6f1tevhn.us.auth0.com',
    useRefreshTokens: true,
    clientId: 'HizGYBysXzxtT4ILPXjDVhFWDCbtW05w',
    authorizationParams: {
      redirect_uri: window.location.origin + '/callback',
      audience: 'https://dev-gbp1ny1a6f1tevhn.us.auth0.com/api/v2/'
    }
  })
)

const toastDefaults = {
  hideProgressBar: false,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  position: 'top-left'
}
const toastPluginOptions: any = {
  transition: 'Vue-Toastification__bounce',
  maxToasts: 20,
  newestOnTop: true,
  toastDefaults: {
    [TYPE.DEFAULT]: { ...toastDefaults },
    [TYPE.INFO]: { ...toastDefaults },
    [TYPE.WARNING]: { ...toastDefaults },
    [TYPE.ERROR]: { ...toastDefaults }
  }
}

app.use(Toast, toastPluginOptions)
app.component('VueDatePicker', VueDatePicker)
app.mount('#app')

// do not change this analytics
posthog.capture('app.mount', {
  mount: true,
  version: import.meta.env.VITE_VERSION || 'v0.0.0-invalid'
})

posthog.capture('advertiser.mount', {
  mount: true,
  version: import.meta.env.VITE_VERSION || 'v0.0.0-invalid'
})
